import React from 'react';
import ServiceSection from './ServiceSection';

export default function HolidayLightingServices() {
  return (
    <div>
      <ul className="timeline timeline-vertical">
        <li>
          <ServiceSection
            title="Residential Installation"
            items={[
              '✓ Roof line lighting',
              '✓ Tree wrapping',
              '✓ Window frame highlights',
              '✓ Custom design planning',
            ]}
          />
          <div className="timeline-middle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 fill-current text-[#85312a]"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <hr className="bg-[#85312a]" />
        </li>
        <li>
          <hr className="bg-[#85312a]" />
          <div className="timeline-middle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 fill-current text-[#85312a]"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <ServiceSection
            title="Commercial Services"
            items={[
              '✓ Storefront decoration',
              '✓ Shopping center displays',
              '✓ `Office building lighting',
              '✓ Commercial timers',
            ]}
            position="end"
            invertLights={true}
            flickerDuration={10} // New flicker duration
          />
          <hr className="bg-[#85312a]" />
        </li>
        <li>
          <hr className="bg-[#85312a]" />
          <ServiceSection
            title="Additional Features"
            items={[
              '✓ LED energy efficiency',
              '✓ Timer installation',
              '✓ Maintenance service',
              '✓ Post-season removal',
            ]}
            invertLights={true}
            flickerDuration={9} // New flicker duration
          />
          <div className="timeline-middle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 fill-current text-[#85312a]"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <hr className="bg-[#85312a]" />
        </li>
      </ul>
    </div>
  );
}
