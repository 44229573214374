import { useState, useEffect } from 'react';
import MenuItems from './menuItems';
import NavMenu from './navMenu';

export default function Navbar() {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [logoOpacity, setLogoOpacity] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      // Trigger the background change after 5 pixels scrolled down
      setIsScrolled(window.scrollY > 5);

      // Calculate the opacity of the logo based on the scroll position
      const opacity = Math.min(1, window.scrollY / 800);
      setLogoOpacity(opacity);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <NavMenu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      <header aria-label="Site Header">
        <div
          className={`fixed w-full px-4 pt-8 pb-10 sm:px-6 lg:px-8 z-50 transition-colors duration-500 ${
            window.innerWidth >= 1024
              ? isScrolled
                ? 'bg-primary shadow-lg'
                : 'bg-transparent'
              : 'bg-primary'
          }`}
          style={{
            opacity:
              window.innerWidth < 1024
                ? 1
                : window.location.pathname !== '/'
                ? 1
                : logoOpacity,
            transition: 'opacity 0.3s',
          }}
        >
          <div className="flex h-16 items-center justify-between">
            {/* logo */}
            <div className="md:flex md:items-center md:gap-12">
              <a
                className="block text-primary"
                href="/"
                style={{
                  opacity:
                    window.innerWidth < 1024
                      ? 1
                      : window.location.pathname !== '/'
                      ? 1
                      : logoOpacity,
                  transition: 'opacity 0.3s',
                }}
              >
                <img
                  src={'/imgs/logo-green.png'}
                  alt="company logo"
                  className={`flex rounded-lg max-w-[12rem]`}
                />
              </a>
            </div>
            {/* desktop navigation */}
            <div className="hidden xl:block">
              <nav aria-label="Site Nav">
                <MenuItems />
              </nav>
            </div>
            {/* mobile menu button */}
            <div className="block pt-8 xl:hidden">
              <button
                className="rounded p-2 text-secondary transition hover:text-secondary/75"
                onClick={() => setMenuIsOpen(!menuIsOpen)}
                type="button"
                title="Menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
