import { useEffect, useRef } from 'react';
import { useAnimation, useInView } from 'framer-motion';
import Review from './review';

export default function Reviews() {
  const controls = useAnimation();
  const ref = useRef(null);
  const ref_2 = useRef(null);
  const isInView = useInView(ref, {
    margin: '-20%', // top, right, bottom, left
  });
  const isInView_2 = useInView(ref_2, {
    margin: '0px 0px 0px 0px', // top, right, bottom, left
  });

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, x: 0 });
    }
  }, [controls, isInView]);

  useEffect(() => {
    if (isInView_2) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, isInView_2]);

  return (
    <section ref={ref_2} className="flex justify-center items-center pt-10">
      <div className="px-4 sm:px-6 xl:mr-0 xl:pl-8 xl:pr-0 py-8">
        <div className="grid grid-cols-1 gap-y-6 xl:grid-cols-3 xl:items-center xl:gap-x-16">
          <div className="text-center sm:text-left bg-primary text-secondary rounded-lg pl-2">
            <h2 className="text-[3rem] sm:text-[3rem] font-bold tracking-tight text-start max-w-[80%]">
              Our Testimony
            </h2>
          </div>

          <div className="-mx-6 xl:col-span-2 xl:mx-0 px-4">
            <div className="swiper-container !overflow-hidden">
              <div className="swiper-wrapper" ref={ref}>
                <Review
                  author="Emily Townsend"
                  review="Mulroony Christmas Lights transformed our home into a winter wonderland! Their professionalism and attention to detail were remarkable. They handled everything from design to installation and made sure every light was perfect. Highly recommend for anyone looking to add some holiday cheer!"
                  quote="Remarkable attention to detail!"
                  direction="right"
                />

                <Review
                  author="Jake Harmon"
                  review="The team at Mulroony Christmas Lights did a fantastic job with our outdoor holiday decorations. They were efficient, polite, and left no mess behind. Our neighbors and friends were stunned by the beautiful display. Can't wait to see what they do next year!"
                  quote="Stunning display!"
                  direction="left"
                />

                <Review
                  author="Sara P."
                  review="I hired Mulroony Christmas Lights for our annual holiday party and they exceeded all expectations. The lights were absolutely gorgeous and set the perfect mood for our celebration. Their team was friendly, on-time, and incredibly skilled. Definitely using them again!"
                  quote="Exceeded all expectations!"
                  direction="left"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
