import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Phone from './components/phone';
// Main Application Component
function App() {
  return (
    <>
      <div className="bg-secondary font-lust overflow-x-hidden overflow-y-hidden scrollbar-hide">
        <Navbar />

        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>

        <Phone />

        <div className="pb-20 bg-primary">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
